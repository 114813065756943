// @flow
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/header';
import Page from '../components/Page';

export default () => {
  return (
    <Page title="Nondiscrimination Policy">
      <Header
        thin
        title="Meet our team"
        subtitle="Dealing with a spine problem can be anxiety inducing. Our commitment is to help you through your care and recovery, treating you as a person, not just a patient."
        cta="Make an appointment"
      />
      <div style={{ maxWidth: 1040, margin: 'auto' }}>
        <br />
        <br />
        <p>
          <strong>
            Appendix A to Part 92—Notice Informing Individuals About
            Nondiscrimination and Accessibility
            <br />
            Requirements and Nondiscrimination Statement:
            <br />
            Discrimination is Against the Law
          </strong>
        </p>
        <p>
          Spine Institute of Idaho complies with applicable Federal civil rights
          laws and does not discriminate on the basis of race, color, national
          origin, age, disability, or sex. Spine Institute of Idaho does not
          exclude people or treat them differently because of race, color,
          national origin, age, disability, or sex.
        </p>
        <p>Spine Institute of Idaho:</p>
        <ul>
          <li>
            Provides free aids and services to people with disabilities to
            communicate effectively with us, such as:
          </li>
          <li>
            Provides free language services to people whose primary language is
            not English, such as:
          </li>
        </ul>
        <p>
          If you need these services, contact any of the Spine Institute of
          Idaho Receptionists or Shelby Stephens, Clinic Supervisor
        </p>
        <p>
          If you believe that Spine Institute of Idaho has failed to provide
          these services or discriminated in another way on the basis of race,
          color, national origin, age, disability, or sex, you can file a
          grievance with: Shelby Stephens, Clinic Supervisor, 360 E. Montvue Dr.
          Ste 100 Meridian, ID 83642, Phone: 208-855-2900 Fax: 208-898-9877. You
          can file a grievance in person or by mail, or fax. If you need help
          filing a grievance, Shelby Stephens, Clinic Supervisor is available to
          help you.
        </p>
        <p>
          You can also file a civil rights complaint with the U.S. Department of
          Health and Human Services, Office for Civil Rights, electronically
          through the Office for Civil Rights Complaint Portal, available at
          https://ocrportal.hhs.gov/ocr/portal/lobby.jsf, or by mail or phone
          at:
        </p>
        <p>
          U.S. Department of Health and Human Services
          <br />
          200 Independence Avenue, SW
          <br />
          Room 509F, HHH Building
          <br />
          Washington, D.C. 20201 <br />
          1-800-368-1019, 800-537-7697 (TDD) <br />
        </p>
        <p>
          Complaint forms are available at
          http://www.hhs.gov/ocr/office/file/index.html.
        </p>
        <p>
          <strong>
            Appendix B to Part 92—Tagline Informing Individuals With Limited
            English Proficiency of Language Assistance Services
          </strong>
        </p>
        <p>
          ATTENTION: If you speak English, language assistance services, free of
          charge, are available to you. Call 1-208-855-2900.
          <br />
          ATENCIÓN: si habla español, tiene a su disposición servicios gratuitos
          de asistencia lingüística. Llame al 1-208-855-2900 (TTY:
          1-208-855-2900).
          <br />
          <br />
          注意：如果您使用繁體中文，您可以免費獲得語言援助服務。請致電
          1-208-855-2900（TTY：1-208-855-2900）。 OBAVJEŠTENJE: Ako govorite
          srpsko-hrvatski, usluge jezičke pomoći dostupne su vam besplatno.
          Nazovite 1-208-855-2900 (TTY- Telefon za osobe sa oštećenim govorom
          ili sluhom: 1-208-855-2900).
          <br />
          <br /> 주의: 한국어를 사용하시는 경우, 언어 지원 서비스를 무료로
          이용하실 수 있습니다. 1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br /> 번으로 전화해 주십시오.  ध्यानाकर्षण: तपाईं [सम्मिलित भाषा],
          भाषा सहायता सेवाहरू, नि: शुल्क कुरा भने, उपलब्ध छन्। 1-208-855-2900 (:
          1-208-855-2900).
          <br />
          <br /> टिटिवाइ) कल। ।CHÚ Ý: Nếu bạn nói Tiếng Việt, có các dịch vụ hỗ
          trợ ngôn ngữ miễn phí dành cho bạn. Gọi số 1-208-855-2900 (TTY:
          1-208-855-2900).
          <br />
          <br /> تنبيه: إذا كنت تتحدث اللغة [إدراج]، خدمات المساعدة اللغوية،
          مجانا، تتوفر لك. دعوة 1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br /> ACHTUNG: Wenn Sie Deutsch sprechen, stehen Ihnen kostenlos
          sprachliche Hilfsdienstleistungen zur Verfügung. Rufnummer:
          1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br /> PAUNAWA: Kung nagsasalita ka ng Tagalog, maaari kang gumamit ng
          mga serbisyo ng tulong sa wika nang walang bayad. Tumawag sa
          1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br /> ВНИМАНИЕ: Если вы говорите на русском языке, то вам доступны
          бесплатные услуги перевода. Звоните 1-208-855-2900 (телетайп:
          1-208-855-2900).
          <br />
          <br /> ATTENTION : Si vous parlez français, des services d'aide
          linguistique vous sont proposés gratuitement. Appelez le
          1-208-855-2900 (ATS : 1-208-855-2900).
          <br />
          <br />
          注意事項：日本語を話される場合、無料の言語支援をご利用いただけます。1-208-855-2900（TTY:
          1-208-855-2900）まで、お電話にてご連絡ください。 ATENȚIE: Dacă vorbiți
          limba română, vă stau la dispoziție servicii de asistență lingvistică,
          gratuit. Sunați la 1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br /> ICITONDERWA: Nimba uvuga Ikirundi, uzohabwa serivisi zo gufasha
          mu ndimi, ku buntu. Woterefona 1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br /> توجه: اگر شما [زبان درج]، خدمات کمک زبان، رایگان صحبت می کنند،
          در دسترس شما هستند. پاسخ 1-208-855-2900 (TTY: 1-208-855-2900).
          <br />
          <br />
        </p>
      </div>
      <Footer />
    </Page>
  );
};
